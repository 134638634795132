@media (width <=768px) {
    .fs-1 {
        font-size: calc(1.375rem + 5vw) !important;
    }

    .fs-4 {
        font-size: calc(1.275rem + 0.6vw) !important
    }
}

.suspense-spinner-container {
    height: 70vh;
    width: 70vw;
}