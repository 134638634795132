@use "@styles/variables.module" as v;

.Footer {
    width: 100%;

    &__Container {
        &__logos {
            padding: 1rem 3rem;
        }

        &__bottomBar {
            padding: 1rem 3rem;
            background: v.$dark-blue;
            width: 100%;

            a {
                color: v.$footer-link
            }
        }

        &__item {
            @media (width <=768px) {
                margin-bottom: 4rem;
            }
        }
    }
}