@use "@styles/variables.module" as v;

.Header {
  &__skip {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:focus {
      position: static;
      width: auto;
      height: auto;
    }
  }

  &__links {
    color: v.$neutro-white;
  }
}