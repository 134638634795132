@use '../../../styles/variables.module' as v;

.ScrollToTop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  //z-index: 1000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: v.$neutro-white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  background-color: v.$blue;
  border: none;

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: v.$dark-blue;
  }
}