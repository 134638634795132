.Error404 {
    &__title {
        font-size: 40px;
    }

    &__description {
        @media (width <=768px) {
            font-size: .9rem;
        }
    }

    &__logo {
        height: 14rem;
        padding-left: 3rem;

        @media (width <=768px) {
            padding-left: 0rem;
        }

        @media (width <=576px) {
            height: 8rem;
        }
    }
}