@use "@styles/variables.module" as v;

.Page {
    &__SectionsContainer {
        &__Section {
            padding: 2rem 5rem;

            @media (width <=768px) {
                padding: 2rem 1rem;
            }

            &:nth-child(even) {

                background-color: v.$pageheader-bg;
            }
        }
    }
}

.formatterTooltip {
    max-width: 50rem;
    white-space: normal;
}